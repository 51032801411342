import img_header from './assets/header.png';
import img_jerome_powell from './assets/jerome-powell.png';
import img_fckfed from './assets/fck-the-fed.png';

import './App.css';

import Marquee from "react-fast-marquee";

// make middle fingers fly to top when clicking on the screen
document.addEventListener("click", function (e) {
  let symbols = ["🖕🏻", "🖕🏼", "🖕🏽", "🖕🏾", "🖕🏿"];
  let numFingers = Math.floor(Math.random() * 10) + 1; // Random number of fingers between 1 and 10
  let wrapper = document.getElementById('middleFingerWrapper'); // Get the wrapper

  for (let i = 0; i < numFingers; i++) {
    let x = Math.floor(Math.random() * window.innerWidth); // Random x position
    let y = window.innerHeight + window.scrollY + Math.floor(Math.random() * 500);

    let middleFinger = document.createElement("img");
    middleFinger.style.transition = "opacity 0.5s"; // Add a transition for opacity to create a fade-out effect

    let randomSymbol = symbols[Math.floor(Math.random() * symbols.length)];

    middleFinger.src = "https://emojicdn.elk.sh/" + randomSymbol;

    middleFinger.style.position = "absolute";
    middleFinger.style.left = x + "px";
    middleFinger.style.top = y + "px";
    middleFinger.style.fontSize = "50px";
    middleFinger.style.zIndex = "9999";
    wrapper.appendChild(middleFinger); // Append the middle finger to the wrapper

    // Animate the middle finger to fly up
    let speed = Math.random() * 2 + 0.5; // Random speed between 0.5 and 2.5
    let id = setInterval(frame, speed);
    function frame() {
      if (y <= window.scrollY) {
        middleFinger.style.opacity = 0; // Start the transition
        setTimeout(function() {
          clearInterval(id);
          middleFinger.remove();
        }, 500); // Wait for the transition to finish before removing the element
      } else {
        y--;
        middleFinger.style.top = y + 'px';
      }
    }
  }
});

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="menu">
          <div className="container">
            <h1>$FCKFED</h1>
            <ul className="nav">
              <li><a href="#about">About</a></li>
              <li><a href="#mission">Mission</a></li>
              <li><a href="#problem">Problem</a></li>
              <li><a href="#why">Why $FCKFED</a></li>
              <li className="cta"><a href="https://pump.fun/EC3f3yGNbLPWFYEgHXRfs76xC8nhz3GiMYQayPweFTxq">Buy Now</a></li>
            </ul>
          </div>
        </div>

        <div className="hero">
          <img className="logo" src={img_header} alt="FCKFED" />
          <img className="jerome" src={img_jerome_powell} alt="Jerome Powell" />
          <img className="fckfed" src={img_fckfed} alt="FCK the FED" />
        </div>
      </header>

      <Marquee
        autoFill={true}
        style={{
          background: "#ee1f25",
          color: "#FFF", // F stands for FCKFED
          padding: "10px",
          fontSize: "20px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Fuck the FED! 🖕🏻&nbsp;
      </Marquee>

      <section id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2>About $FCKFED</h2>
              <p>$FCKFED is the revolutionary memecoin that stands against the traditional financial system and its outdated practices. Born out of frustration with the United States Federal Reserve's monetary policies, $FCKFED aims to bring attention to the economic impact of high-interest rates and the Fed's influence on market volatility.</p>
            </div>
          </div>
        </div>
      </section>
      <section id="mission">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2>Our Mission</h2>
              <h3>TLDR; Fuck the Fed</h3>
              <p>Our mission is simple: to educate, entertain, and empower individuals in the crypto community. We believe in a decentralized future where economic power is in the hands of the people, not controlled by a central authority. $FCKFED is more than a memecoin; it's a movement to raise awareness about the Federal Reserve's policies and their effects on the global economy.</p>
            </div>
          </div>
        </div>
      </section>
      <section id="problem">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2>The Problem with the Federal Reserve</h2>
              <p>The Federal Reserve, often referred to as "the Fed," plays a crucial role in the U.S. economy by managing monetary policy. However, their decisions have far-reaching consequences:</p>
              <ul>
                <li><strong>High-Interest Rates:</strong> By keeping interest rates high, the Fed restricts borrowing, leading to reduced consumer spending and business investment. This often results in slower economic growth and increased unemployment.</li>
                <li><strong>Market Volatility:</strong> The Fed's policy decisions can create uncertainty in the financial markets, causing stock prices to fluctuate wildly and negatively impacting investor confidence.</li>
                <li><strong>Inflation Control:</strong> While the Fed aims to control inflation, their methods can sometimes lead to deflationary pressures, harming economic stability.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section id="why">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2>Why $FCKFED?</h2>
              <p>$FCKFED was created as a satirical yet serious response to these issues. We use humor and memes to spread our message, making economic concepts accessible to everyone. Our community-driven approach ensures that everyone's voice is heard, and together, we can advocate for a fairer financial system.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2>How to Get Involved?</h2>
              <ul>
                <li><strong>Buy $FCKFED:</strong> Join the movement by purchasing $FCKFED tokens. Participate in our mission to challenge the status quo and promote economic reform.</li>
                <li><strong>Spread the Word:</strong> Share our memes and educational content on social media. The more people understand the issues, the stronger our movement becomes.</li>
                <li><strong>Engage with the Community:</strong> Engage with like-minded individuals in our forums and chat groups. Share your ideas, discuss the latest economic news, and collaborate on ways to make an impact.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2>Conclusion</h2>
              <p>$FCKFED is more than just a memecoin; it's a call to action. Together, we can challenge the Federal Reserve's influence, promote lower interest rates, and create a more stable and prosperous economic environment for all.</p>
              <p>Join us in our mission to say "$FCKFED" to the outdated financial system and embrace the future of decentralized finance!</p>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="container">
          <div className="disclaimer">
            <center><small>$FCKFED is a satirical cryptocurrency created for educational and entertainment purposes. It is not financial advice, and investing in cryptocurrencies involves risk. Always do your own research before making any investment decisions.</small></center>
          </div>
        </div>
      </footer>

      <div id="middleFingerWrapper" style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflow: 'hidden'}}></div>
    </div>
  );
}

export default App;
